import React from "react";
import Pagination from "react-js-pagination";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { useState } from "react";
import { useEffect } from "react";
import AdminListService from "../../../services/admin-list.service";

import { useSelector } from "react-redux";
import RegisterService from "../../../services/register.service";
import Select from "react-select";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

export default function ManageRegisteredUsers() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;

  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("20");

  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const adminList = async (limit, page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listRegisteredUsers(limit, page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            store_location: value.store_location,
            rsaName: value.rsa_fname + " " + value.rsa_lname,
            customerEmail: value.cust_email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            employee_F: value.sales_fname,
            employee_L: value.sales_lname,
            phone: value.phone,
            comments: value.comments,

            date: value.date,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      adminList(limit, currentPage);
      async function getStoreLocation() {
        let errors = {};

        try {
          //FIXME remove this service if not used
          const response = await RegisterService.getStoreLocation();
          let value = response.data.data;
          const results = [];
          value.map((value) => {
            return results.push({
              value: value.id,
              label: value.store_location,
            });
          });
          await setLocations([...results]);

          if (response.data.status === 429 || response.data.status === "429") {
            alert("Max Limit Reached. Please wait.");
          }
          if (value.includes("redirect")) {
          } else {
            errors["questionnare"] = response.data.response;
          }
        } catch (err) {
          setLocations([]);
          if (err.status === 429 || err.status === "429") {
            alert("Max Limit Reached. Please wait.");
          }
        }
      }
      getStoreLocation();
    }
  }, [adminAccessToken]);

  const searchInTable = async (limit, page, values) => {
    setLoading(true);
    try {
      const responce = await AdminListService.searchRegisteredData(
        limit,
        page,
        values
      );

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            id: value.id,
            store_location: value.store_location,
            rsaName: value.rsa_fname + " " + value.rsa_lname,
            customerEmail: value.cust_email,
            customer_F: value.cust_fname,
            customer_L: value.cust_lname,
            employee_F: value.sales_fname,
            employee_L: value.sales_lname,
            phone: value.phone,
            comments: value.comments,

            date: value.date,
          });
        });

        settableData([...results]);

        setLimit(responce.data.data.per_page);
        setTotalResults(responce.data.data.total_records);
        setTotalPages(responce.data.data.total_pages);
        setCurrentPage(responce.data.data.current_page);

        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);

      setLimit("20");
      setTotalResults("0");
      setTotalPages("0");
      setCurrentPage(1);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values) => {
        searchInTable(limit, "1", values);
      },
    });

  const delAcc = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const response = await AdminListService.deleteRegisteredUsers(delId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              adminList(limit, "1");
            }, 1500);
          }
        } catch (err) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                No.
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_F")}
                className={getClassNamesFor("customer_F")}
              >
                Customer First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customer_L")}
                className={getClassNamesFor("customer_L")}
              >
                Customer Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("phone")}
                className={getClassNamesFor("phone")}
              >
                Customer Phone#
              </th>
              <th
                scope="col"
                onClick={() => requestSort("customerEmail")}
                className={getClassNamesFor("customerEmail")}
              >
                Customer Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee_F")}
                className={getClassNamesFor("employee_F")}
              >
                Employee First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("employee_L")}
                className={getClassNamesFor("employee_L")}
              >
                Employee Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_location")}
                className={getClassNamesFor("store_location")}
              >
                Store Location & Employee Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("comments")}
                className={getClassNamesFor("comments")}
              >
                Comments
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Active")}
                className={getClassNamesFor("Active")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.date}</td>

                  <td>{el.customer_F}</td>
                  <td>{el.customer_L}</td>
                  <td>{el.phone}</td>
                  <td>{el.customerEmail}</td>

                  <td>{el.employee_F}</td>
                  <td>{el.employee_L}</td>
                  <td>{el.store_location}</td>
                  <td>
                    <span
                      style={{
                        display: "block",
                        width: "200px",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {el.comments}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <button
                        type="button"
                        className="text-danger open-model-btn"
                        title="De Activate"
                        onClick={() => {
                          delAcc(el.id);
                        }}
                      >
                        <i className="bi bi-trash-fill"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              if (values.fieldtype !== "" && values.searchval !== "") {
                searchInTable(limit, e, values);
              } else {
                adminList(limit, e);
              }
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };
  const exportRecord = async () => {
    setLoading(true);
    try {
      const responce = await AdminListService.exportRegisterUsers(values);
      let resultData = responce.data.data;
      const worksheet = XLSX.utils.json_to_sheet(resultData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${responce.data.fileName}`);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.warn(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">
                Registered Users [{totalResults}]
                {totalResults > 0 && (
                  <button
                    type="button"
                    onClick={exportRecord}
                    className="btn btn-primary d-inline-block float-end btn-sm"
                  >
                    Export Record
                  </button>
                )}
              </h5>
            </div>

            <div className="card-body">
              <div className="clearfix"></div>
              <form className="row gy-3" onSubmit={handleSubmit} noValidate>
                <div className="col-lg-3">
                  <div className="input-field-box mb-lg-0">
                    <label>Show Record</label>
                    <select
                      className="form-select"
                      onChange={(e) => {
                        if (
                          values.fieldtype !== "" &&
                          values.searchval !== ""
                        ) {
                          searchInTable(e.target.value, "1", values);
                        } else {
                          adminList(e.target.value, "1");
                        }
                      }}
                    >
                      <option value="20">20</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="1500">1500</option>
                      <option value={totalResults}>All</option>
                    </select>
                  </div>
                </div>

                <div className="col-lg-3 ms-auto">
                  <div className="input-field-box mb-lg-0">
                    <label>Select Column</label>
                    <select
                      className={`form-select ${
                        errors.fieldtype && touched.fieldtype ? "is-danger" : ""
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("searchval", "");
                      }}
                      name="fieldtype"
                      value={values.fieldtype || ""}
                      required
                    >
                      <option value="">Select Column</option>
                      <option value="date">Date</option>

                      <option value="fname">Customer First Name </option>
                      <option value="lname">Customer Last Name </option>
                      <option value="phone">Customer Phone# </option>
                      <option value="email">Customer Email </option>

                      <option value="sales_name_f">Employee First Name</option>
                      <option value="sales_name_l">Employee Last Name</option>
                      <option value="store_location">
                        Store Location & Employee Name
                      </option>
                      <option value="comments">Comments</option>
                    </select>
                  </div>
                  {errors.fieldtype && touched.fieldtype ? (
                    <span className="text-danger">{errors.fieldtype}</span>
                  ) : null}
                </div>

                <div className="col-lg-4 ps-lg-0">
                  <div className="input-field-box mb-lg-0">
                    <label>Search</label>
                    <div className="input-group">
                      {values.fieldtype === "store_location" ? (
                        locations.length ? (
                          <Select
                            className="border-0 form-control form-label mb-0 p-0"
                            placeholder={"Store Location & Employee Name"}
                            options={locations}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFieldValue(
                                  "searchval",
                                  selectedOption?.value
                                );
                              } else {
                                setFieldValue("searchval", "");
                              }
                            }}
                          />
                        ) : null
                      ) : (
                        <input
                          type={values.fieldtype === "date" ? "date" : "text"}
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />
                      )}

                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button
                        className="btn btn-primary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                  {errors.searchval && touched.searchval ? (
                    <span className="text-danger">{errors.searchval}</span>
                  ) : null}
                </div>
              </form>
              <Table data={tableData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
